<template>
  <div>
    <div class="outter">
      <div class="container">
        <div class="nav clearFix">
          <div>
            <span @click="to('/')">首页</span>
          </div>
          <div>
            <img src="../assets/image/leftMenu.png" alt="">
            <span @click="to('/news')">新闻动态</span>
          </div>
          <div>
            <img src="../assets/image/leftMenu.png" alt="">
            <span>{{info.title}}</span>
          </div>
        </div>
        <div class="article">
          <h1>{{info.title}}</h1>
          <div class="editor">
            <span>编辑：{{info.author}}</span>
            时间：{{info.date}}
          </div>
          <div class="line"></div>
          <div v-html="info.content" class="html"></div>
          <div class="introduce">
            <div class="title clearFix">
              <span class="icon fl"></span>
              <span class="fl">热门推荐</span>
            </div>
            <div class="recommendLine"></div>
            <ul>
              <li v-show='nowid != item.id && index < 5' @click="replaceTo(item.id)" v-for="(item,index) in newsList"
                :key="index">
                <span class="fl">{{item.title}}</span>
                <div class="fr">
                  <img src="../assets/image/clock.png" alt="">
                  {{item.date}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="float-btn-area">
      <div class="qr-code-box" v-show="qrCodeShow">
        <vue-qr class='qr-code' :text="info.linkUrl" :size="92" :margin="4"></vue-qr>
        <div class="qr-text">微信扫一扫</div>
      </div>
      <div class="float-content">
        <div @mouseenter="qrCodeShow=true" @mouseleave="qrCodeShow=false">
          <img v-show="!qrCodeShow" class="content-img" src="../assets/image/detail/ico-wx.svg" alt="">
          <img v-show="qrCodeShow" class="content-img" src="../assets/image/detail/ico-wx-hover.svg" alt="">
        </div>
        <img class="content-img mt12" @click="backToTop" src="../assets/image/detail/ico-back-to-top.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Loading } from 'element-ui'
import vueQr from 'vue-qr'

export default {
  name: 'detail',
  components: {
    vueQr
  },
  data() {
    return {
      nowid: '',
      newsList: '',
      info: {
        title: '',
        auth: '',
        linkUrl:'',
      },
      qrCodeShow:false,
    }
  },
  created() {
    this.nowid = this.$route.query.id
    this.newsList = JSON.parse(sessionStorage.getItem('list')).body
    // let options = {
    //   target: document.querySelector('.article')
    // }
    // Loading.service(options)
    Vue.prototype
      .$post('/news/detail', {
        id: this.$route.query.id
      })
      .then(res => {
        // let loadingInstance = Loading.service(options)
        // loadingInstance.close()
        this.info = res.data
      })
  },
  mounted() {
    // let options = {
    //   target: document.querySelector('.article')
    // }
    // Loading.service(options)
  },
  methods: {
    to(e) {
      window.scrollTo(0, 0)
      if (e != this.$route.path) {
        this.$router.push({
          path: e
        })
      }
    },
    replaceTo(e) {
      this.$router.replace({
        path: '/empty?id=' + e
      })
    },
    backToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
},
}
</script>
<style lang="less">
body {
  background: #f9f9f9;
}
</style>
<style lang="less" scoped>
.outter{
  background: #F9F9F9;
}
.container {
  width: 1200px;
  margin: auto;
}
.nav {
  margin: 36px 37px 20px 24px;
  div {
    float: left;
    line-height: 29px;

    img {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      font-size: 16px;
      color: #454545;
    }
  }
  div:first-child {
    img {
      width: 16px;
      height: 14px;
      padding-right: 9px;
    }
    span {
      cursor: pointer;
      color: #00B095;
    }
  }
  div:nth-child(2) {
    img {
      width: 6px;
      height: 9px;
      padding: 0 8px;
    }
    span {
      cursor: pointer;
      color: #00B095;
    }
  }
  div:nth-child(3) {
    img {
      width: 6px;
      padding: 0 16px 0 8px;
      height: 9px;
    }
  }
}
.article {
  width: 1200px;
  margin: 0 auto 54px auto;
  background: #fff;
  box-sizing: border-box;
  padding: 38px 30px 42px;
  border-radius: 20px;

  .introduce {
    width: 100%;
    padding: 24px 0 0;
    margin-top: 56px;
    background: #F9F9F9;
    border-radius: 20px;

    .title {
      color: #2a2a2a;
      font-size: 24px;

      .icon {
        display: block;
        margin: 4px 12px 0 30px;
        width: 4px;
        height: 25px;
        background-color: rgba(0, 176, 149, 1);
      }
      img {
        width: 128px;
        height: 9px;
        display: block;
        margin: 8px auto 0 auto;
      }
    }

    .recommendLine {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0 0;
    }

    ul {
      padding: 23px 30px 23px 29px;
      li {
        overflow: hidden;
        position: relative;
        color: #666666;
        font-size: 16px;
        padding: 0 0 20px 18px;
        line-height: 19px;
        cursor: pointer;

        &:hover {
          span:first-child {
            color: rgba(0, 176, 149, 1);
          }
        }

        .fl {
          width: 700px;
        }
        .fr {
          img {
            width: 18px;
            height: 18px;
            position: absolute;
            right: 109px;
            top: 0;
          }
        }
      }
      li::before {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        background-color: rgba(0, 176, 149, 1);
        border-radius: 50%;
        left: 0;
        top: 6px;
      }
      li.oncolor {
        .fl {
          color: #00cdb7;
        }
      }
    }
  }
}
</style>
<style lang="less">
.article {
  h1 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }
  .editor {
    color: #777;
    font-size: 16px;
    padding: 20px 0 0;
    span {
      margin-right: 13px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    padding: 0;
    margin: 32px 0;
  }
  p {
    color: #454545;
    font-size: 14px;
    line-height: 29px;
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 22px;
  }
  .html{
    padding: 0 20px;
  }
  img {
    max-width: 100%
  }
}

.float-btn-area{
  position: fixed;
  right:50px;
  @media screen and(min-width: 1400px) {
      right: calc(50% - 650px);
  }
  bottom: 200px;
  .float-content{
    .content-img {
        width: 34px;
        height: 34px;
        display: block;
        &.mt12{
          margin-top: 12px;
        }
      }
    }
  .qr-code-box{
    position: absolute;
    bottom:-5px;
    left:-4px;
    transform: translateX(-100%);
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
    .qr-text {
        margin-top: 4px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 12px;
        letter-spacing: 4px;
      }
  }
}
</style>